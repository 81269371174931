<template>
  <div class="pb-5">
    <h4>{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <h5>What makes your venue unique/special?</h5>
            <v-textarea
              label="Event Venue Description"
              v-model="form.description"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="#F9F9F9" flat class="pa-3"
              >What name is the hotel known by?</v-card
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <h5 class="mb-2">Meeting & Events</h5>
            <spaces :form="form"></spaces>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="#F9F9F9" flat class="pa-3"
              >Let us know about the basic information of your venue or
              hotel</v-card
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn
      color="primary"
      @click="this.continue"
      class="float-right mt-5 mb-5 mr-3"
      >Continue</v-btn
    >
    <v-btn
      color="secondary"
      class="float-right mt-5 mb-5 mr-1"
      @click="submit('draft')"
      v-if="
        (getVendor && getVendor.status !== 'active') ||
        getIsVendorUpdate === false
      "
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const Spaces = () =>
  import(/* webpackChunkName: "spaces" */ "@/components/sections/Spaces");

export default {
  name: "AboutLocation",
  props: ["name", "index", "form"],
  components: { Spaces },
  data: () => ({
    seatingArrangements: [
      {
        name: "Theatre",
        icon: "mdi mdi-theater",
      },
      {
        name: "Banquet",
        icon: "mdi mdi-office-building-outline",
      },
      {
        name: "U-Shape",
        icon: "mdi mdi-alpha-u-box",
      },
      {
        name: "Cabaret",
        icon: "mdi mdi-account-music",
      },
      {
        name: "Board",
        icon: "mdi mdi-bulletin-board",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "getRules",
      "getNewVendorSpace",
      "getVendor",
      "getIsVendorUpdate",
    ]),
  },
  methods: {
    /*removeLocationSpace(index) {
      this.form.vendor_spaces = _.reject(this.form.vendor_spaces, (locationSpace, locationSpaceIndex) => { return locationSpaceIndex === index; });
    },*/
    setActive(seatingArrangement) {
      if (
        this.form.seating_arrangements &&
        this.form.seating_arrangements.length > 0
      ) {
        if (
          this.form.seating_arrangements.some(
            (arrangement) => arrangement.name === seatingArrangement.name
          )
        ) {
          this.form.seating_arrangements =
            this.form.seating_arrangements.filter(
              (item) => item.name !== seatingArrangement.name
            );
        } else {
          let arrangements = [];
          this.form.seating_arrangements.forEach((ar) => {
            arrangements.push(ar);
          });
          this.form.seating_arrangements = [];
          arrangements.push(seatingArrangement);
          arrangements.forEach((arrangement) => {
            if (arrangement.name.replace(/ /g, "") !== "") {
              this.form.seating_arrangements.push({
                name: arrangement.name,
                icon: arrangement.icon,
              });
            }
          });
        }
      } else {
        this.form.seating_arrangements = [];
        this.form.seating_arrangements.push(seatingArrangement);
      }
    },
    seatingArrangementSelected(seatingArrangement) {
      let isSelected = false;
      if (this.form.seating_arrangements) {
        this.form.seating_arrangements.forEach((seating_arrangement) => {
          if (seating_arrangement.name === seatingArrangement.name) {
            isSelected = true;
          }
        });
      }
      return isSelected;
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    addSpace() {
      this.form.vendor_spaces.push(new Form(this.getNewVendorSpace));
    },
  },
};
</script>